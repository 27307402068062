import xss, { escapeAttrValue } from 'xss';
import { ParsedUrlQuery } from 'querystring';
import {
    INVOCA_CDN,
    HVAC_ID,
    REVIEWS_VALUES,
    BASE_URL_PREFIX,
    SESSION_STORAGE_KEYS,
} from '../constants';
import set from 'date-fns/set';
import { format } from 'date-fns';
import getConfig from 'next/config';
import {
    IGeolocationData,
    IMarket,
    IServiceType,
} from '../../interfaces/shared';
import { getGeoLocation } from '../../services';
import { getWorkingHours } from '../../services/zesty';

interface CustomWindow extends Window {
    InvocaTagId: string;
    InvocaSettings: Record<string, string | undefined>;
    app: {
        appVars: {
            currentMarket: {
                id: string;
            };
            marketPageSettings: {
                service: string;
            };
            refObject: {
                refToken: string;
            };
        };
    };
}

declare let window: CustomWindow;

export function getMarketId(urlServiceId: string | undefined) {
    const hasUrlMarketIdOnly = urlServiceId?.indexOf('-') === -1;

    const marketId = hasUrlMarketIdOnly
        ? urlServiceId
        : urlServiceId?.split('-')?.[0];

    return marketId;
}

export function getServiceId(urlServiceId: string | undefined) {
    return urlServiceId?.split('-')?.[1];
}

export function getSid(query: ParsedUrlQuery) {
    const sid = (query?.sid as string) || undefined;
    return sid;
}

export const cleanMarkup = (markup: string) => ({
    __html: xss(markup, {
        onIgnoreTagAttr: function (_, name, value) {
            if (['class', 'style', 'start'].includes(name)) {
                return name + '="' + escapeAttrValue(value) + '"';
            }
        },
    }),
});

export function loadInvoca(
    attributes: Record<string, string | undefined> = {},
) {
    const {
        publicRuntimeConfig: { INVOCA_CLIENT_ID = '' },
    } = getConfig();

    window.InvocaTagId = INVOCA_CLIENT_ID;
    window.InvocaSettings = {
        ...attributes,
    };

    window.app = {
        appVars: {
            currentMarket: {
                id: attributes?.marketId || '',
            },
            marketPageSettings: {
                service: attributes?.serviceTypeId || '',
            },
            refObject: {
                refToken: attributes?.sid || '',
            },
        },
    };

    const invocaScript = document.getElementById('invoca-script');
    if(invocaScript) {
        return;
    }

    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.async = true;
    scriptTag.id = 'invoca-script';
    scriptTag.src =
        ('https:' === document.location.protocol ? 'https://' : 'http://') +
        INVOCA_CDN;

    const mainScriptTag = document.getElementsByTagName('script')[0];
    mainScriptTag.parentNode &&
        mainScriptTag.parentNode.insertBefore(scriptTag, mainScriptTag);
}

export const getRatingsValues = (serviceId: string | undefined) => {
    return serviceId && REVIEWS_VALUES[serviceId]
        ? REVIEWS_VALUES[serviceId]
        : REVIEWS_VALUES[HVAC_ID];
};

export const formatNumberWithCommas = (number: string) =>
    number.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const replaceWithPrice = (text: string, amount: string) =>
    text && amount && text.replace(/%price%/g, amount);

export const truncateText = (text: string, length: number) => {
    if (text.length <= length) {
        return text;
    }

    return text.substring(0, length) + '\u2026 ';
};

export function toAmPm(time: string) {
    const hours = time.split(':')[0];
    const minutes = time.split(':')[1];
    const ampm = Number(hours) < 12 ? 'am' : 'pm';
    const convertedTime = Number(hours) > 12 ? Number(hours) - 12 : hours;
    return Number(convertedTime) + ':' + minutes + ' ' + ampm;
}

export function formatPhoneNumber(phoneNumberString: string) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
            '',
        );
    }
    return null;
}

export function getFormattedTime(time: string) {
    const splitTime = time.split(':');
    const updatedDate = set(new Date(), {
        hours: Number(splitTime[0]),
        minutes: Number(splitTime[1]),
    });

    try {
        const formattedTime = format(updatedDate, 'h:mmaaa');
        return formattedTime;
    } catch (error) {
        return undefined;
    }
}

export function getUrlQueryString<T>(obj: Record<keyof T, string | number>) {
    const queryString = Object.keys(obj)
        .filter((key) => obj[key as keyof T] && obj[key as keyof T] !== '')
        .map((key) => `${key}=${encodeURIComponent(obj[key as keyof T])}`)
        .join('&');

    return queryString ? `?${queryString}` : '';
}

export const slugify = (str: string | null | undefined) =>
    str &&
    str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-')
        .replace(/^-+|-+$/g, '');

export const getHyphenatedText = (text: string) =>
    text
        ? text.trim().toLowerCase().replace(/\s|\//g, '-').replace(/-+/g, '-')
        : '';

export const getBookingLink = ({
    market,
    serviceType,
    params: paramsArg,
}: {
    market: IMarket | undefined;
    serviceType?: IServiceType;
    params?: Record<string, string | number>;
}) => {
    let params = { ...paramsArg };

    if (typeof window !== 'undefined') {
        const storageParamsString = sessionStorage.getItem(
            SESSION_STORAGE_KEYS.homeBookingParams,
        );
        if (storageParamsString) {
            const storageParams = JSON.parse(storageParamsString);
            params = {
                ...params,
                ...storageParams,
            };
        }
    }

    if (!market || !market.id) {
        market = {
            id: '1',
            name: 'Los Angeles',
        };
    }

    let urlTitle = getHyphenatedText(market.name);
    let urlId = market.id;

    if (serviceType) {
        urlTitle = `${getHyphenatedText(serviceType.name)}-${getHyphenatedText(
            market.name,
        )}`;
        urlId = `${market.id}-${serviceType.id}`;
    }

    const queryString = params ? getUrlQueryString(params) : '';

    return `${BASE_URL_PREFIX}/${urlTitle}/service/${urlId}${queryString}`;
};

export function parsePromiseAllResponse<T extends { [key: string]: any }>(
    response: PromiseSettledResult<any>[],
    promiseData: Array<T[keyof T]>,
) {
    return response.reduce((prev, curr, idx) => {
        if (curr.status === 'fulfilled') {
            const index: keyof T = promiseData[idx];
            prev[index] = curr.value;
        }
        return prev;
    }, {} as T);
}

export async function getGeoLocationHelper() {
    try {
        const response = await getGeoLocation();
        const message = response.message.split(',');
        const res = message.reduce(
            (acc: { [x: string]: any }, curr: string) => (
                (acc[curr.split('=')[0]] = curr.split('=')[1]), acc
            ),
            {},
        );

        return res as IGeolocationData;
    } catch (err) {
        console.error('There was an error geting the geolocation: ', err);

        return null;
    }
}

export async function getWorkingHoursHelper() {
    const workingHours = await getWorkingHours('default');
    return workingHours;
}

export {
    getBookingFieldValidationError,
    getMultipleFieldBookingError as getMultipleFieldError,
    getBookingFieldsValidationResult,
} from './validation';
